import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`From invoices for online shopping to official notices: PDF documents are ubiquitous today. Numerous companies
and organizations use the file format to display and exchange information. But are PDFs also accessible?
The short answer: It depends!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHoZWTi44n/xAAcEAACAQUBAAAAAAAAAAAAAAABAgMAEBESITH/2gAIAQEAAQUCkcq3dwWqTxTi3//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAQABBQEAAAAAAAAAAAAAAAERAAIQITFB/9oACAEBAAY/AgDtRa7K8xaR1jH/xAAaEAADAQADAAAAAAAAAAAAAAAAARExIUHR/9oACAEBAAE/IYfFpwvLVpcH14EqEXYqMSR//9oADAMBAAIAAwAAABBrD//EABcRAQADAAAAAAAAAAAAAAAAAAARIVH/2gAIAQMBAT8QrEv/xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QjH//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFhgdHw/9oACAEBAAE/EHNYAWM819uDrICm0e+5Y1GcLS+4RtCNwzQqHMAXzqGIug5n/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A document with text blocks and graphics.",
          "title": "A document with text blocks and graphics.",
          "src": "/static/51b74b5ce5bbce433e7a8554cd2cb535/e5166/pexels-antoni-shkraba-document.jpg",
          "srcSet": ["/static/51b74b5ce5bbce433e7a8554cd2cb535/f93b5/pexels-antoni-shkraba-document.jpg 300w", "/static/51b74b5ce5bbce433e7a8554cd2cb535/b4294/pexels-antoni-shkraba-document.jpg 600w", "/static/51b74b5ce5bbce433e7a8554cd2cb535/e5166/pexels-antoni-shkraba-document.jpg 1200w", "/static/51b74b5ce5bbce433e7a8554cd2cb535/b17f8/pexels-antoni-shkraba-document.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © ANTONI SHKRABA production / pexels.com`}</em></p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/PDF/UA"
      }}>{`PDF/UA`}</a>{` standard was published in 2012. It defines how a PDF document
can meet accessibility requirements. Unfortunately, even today many documents do not comply with this standard.`}</p>
    <p>{`To prevent this from happening to you, you should check your PDFs for accessibility. The audit is best started with
the `}<BoldText mdxType="BoldText">{`PDF Accessibility Checker`}</BoldText>{`, or PAC for short. This free standard tool has recently been
released in version 2024: `}<a parentName="p" {...{
        "href": "https://pac.pdf-accessibility.org/en"
      }}>{`https://pac.pdf-accessibility.org/en`}</a></p>
    <p>{`I have been using the tool for several years now and appreciate its strengths. However, the automated tool does not
replace a manual check, for example with a screen reader and keyboard. I will therefore give you a brief overview of
the strengths and weaknesses of the PDF Accessibility Checker.`}</p>
    <h2>{`The tool's strengths`}</h2>
    <p>{`The PAC is a tool for automatically checking PDF documents for accessibility in accordance with the PDF/UA standard
(DIN/ISO 14289-1). In addition, relevant points of the `}<ItalicText mdxType="ItalicText">{`Web Content Accessibility Guidelines (WCAG)`}</ItalicText>{`
and additional quality features are checked.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABJ0AAASdAHeZh94AAABsklEQVQ4y41T2W7cMAz0//9HP6QPeelLURRI026Rtpt1LOuWfKx8yFOQGxmbBFtUwICSTA05JF09fDvg4f6A09MzzuczUko7pmliOwwDjNZo25YhhICUElprGGNeofr54xe+fPqMw/dHlLVtGzIhZ6w5Y0oJ3jnEGJDOl0DzPGNZFlhroZSCc+5C2AUPLIkdQoh8SR+7ruPMCOM48jmlEc4Z3q/rysFJwTiMHIQUViYM+PDxHrVooaTE8XhE0zRMWsjI9l3PGcfomYRUbEXFuvKebBW6HndfH2GdRwyBo1M0IiogQrpflhnOWYQQmKiQkDo6UwkqYxS6YKFki9Of32gawbL7vt8ldzHCaY01L/DessS91i8Z7oTUrVNdc+dK18h671k22bLPeUUIDt75vYbvCI2RXGiSeb22LbMUciZQw+ZlgtYSSundvxASLoRWQYhnllhkFJADPSB4R1Ijl2BK0z9qaCWapoZznh2uScmhRKc5XObpnc9bVD54Tp8ev13Xo8GdvUHyijDGWJ7fdLrMX8T/rEpqi7o10DbA07i8jMo+MiHAiAatEPybUbfJ3sJf0afuqpmL9xMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Result of the accessibility check of a PDF document with the PAC 2024.",
          "title": "Result of the accessibility check of a PDF document with the PAC 2024.",
          "src": "/static/a46a44a187a3d3a001e3ec982f15bb8e/c1b63/screenshot-pac-2024-details.png",
          "srcSet": ["/static/a46a44a187a3d3a001e3ec982f15bb8e/5a46d/screenshot-pac-2024-details.png 300w", "/static/a46a44a187a3d3a001e3ec982f15bb8e/0a47e/screenshot-pac-2024-details.png 600w", "/static/a46a44a187a3d3a001e3ec982f15bb8e/c1b63/screenshot-pac-2024-details.png 1200w", "/static/a46a44a187a3d3a001e3ec982f15bb8e/71c1d/screenshot-pac-2024-details.png 1536w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`The PAC can automatically detect the following barriers, among others:`}</p>
    <ul>
      <li parentName="ul">{`No title is set for the PDF document.`}</li>
      <li parentName="ul">{`The language of the document is not defined.`}</li>
      <li parentName="ul">{`The contents of the PDF document are not tagged.`}</li>
      <li parentName="ul">{`An image does not have an alternative text.`}</li>
    </ul>
    <p>{`Furthermore, the tool also transparently shows which guidelines it checks and which it does not in the WCAG tab.
Guidelines such as `}<ItalicText mdxType="ItalicText">{`“2.1 Keyboard Accessible”`}</ItalicText>{` and `}<ItalicText mdxType="ItalicText">{`“3.2 Predictable”`}</ItalicText>{`
can only be checked manually.`}</p>
    <h2>{`The tool's weaknesses`}</h2>
    <p>{`The PDF Accessibility Checker can check the code of a PDF document according to certain rules. However, it cannot
understand and interpret the content of the document. Automated checking tools generally cannot do this.`}</p>
    <p>{`To show the tool's weaknesses, I conducted an experiment: How many barriers can I include in a PDF document without
the PAC sounding the alarm? My `}<a parentName="p" {...{
        "href": "/pdf/pdf_accessibility_test_en.pdf"
      }}>{`test document`}</a>{` consists of several headings, body text
and an image. You can download the document and audit it yourself.`}</p>
    <p>{`Let's now take a closer look at the individual barriers.`}</p>
    <h3>{`No descriptive title`}</h3>
    <p>{`My test document has the title `}<ItalicText mdxType="ItalicText">{`“A Fabulous Title”`}</ItalicText>{`. PAC is satisfied with this. However,
when screen reader users hear this title, they won't be very happy about it.`}</p>
    <p>{`The title of a website or document is the first piece of information that users come across. The title should describe
the content and provide orientation for users. The relevant
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/page-titled.html"
      }}>{`WCAG success criterion 2.4.2`}</a>{` states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Web pages have titles that describe topic or purpose.`}</p>
    </blockquote>
    <p>{`Therefore, during a manual check, a human must assess whether the set title of the PDF document is meaningful enough.`}</p>
    <h3>{`Incorrectly tagged headings`}</h3>
    <p>{`As PAC correctly states, the entire content of my test document is tagged. This means that for each element in the PDF,
the type of content it represents is defined. This information is essential for screen reader users, among others, in order
to correctly understand the content and quickly navigate through the document.`}</p>
    <p>{`However, I have fooled PAC: All headings, such as `}<ItalicText mdxType="ItalicText">{`“A fabulous heading”`}</ItalicText>{`, are marked as body text
with the `}<InlineCode mdxType="InlineCode">{`<`}{`P`}{`>`}</InlineCode>{` tag. While sighted people can visually recognize the headings as such, this
information is not captured semantically. The
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/info-and-relationships.html"
      }}>{`WCAG success criterion 1.3.1`}</a>{` requires:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Information, structure, and relationships conveyed through presentation can be programmatically determined or are
available in text.`}</p>
    </blockquote>
    <p>{`Automated tools such as the PAC cannot assess what should be tagged as a heading and what should not. Therefore, a person
should have the PDF document read aloud by a screen reader. In addition, PAC itself also offers the useful screen reader
preview function, which visualizes the tagging structure.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABJ0AAASdAHeZh94AAAC30lEQVQozz2SW09TaRSG96Uzib/Ai7n0B5AQAokJRhL0zngzEmeYxKhR44i0SkRjTEacMHgWVMACpS2HkjJVKqUnWnrkUERLC3RbsLvlqGQqHRAIh8fs7eFLnqy1Lr7vXWt9r/DS5SIwaiUmjhKZHCYaDxOfGSORfPuD+MxrFpanqbl7h5ycHIqLiyksLKSgoIC8vDxyc3OVPD8/H+HYnxe4ry3F5m/F7HrGi/76H1g8DQpy3uNuoN/nZmhwCKfTidvtVqLH48HlchEKhYhEIggnLqt50n4GZ6gdi0dDr7cFq+8rvd5mbAEt7mEDA8M6xBmRjfVNkkmJdHoWSUohSWmF2bl5dndBOK5SUd95EV+4C7t8eciAL9yO9xv+0Q4G3xpxBluJTIyz9v8aCwuLLC4usbT0gUwmQ3blE6srn9jc2EAoUauoazuLI9BGj1tDn1+rIHcoC9gDrUqXFreGuBhl5AM8HdvGEN3GGNumZxr0U/DPyGd80gpCiUrFQ905Xg3oMTsblQccQR3OoA7XoB5XSK9EWSyRiNEx/pmS9jmuWpe53vcfNY5lqurq+f38Mcy9Wnnkchq7TuMLd2LzN2H3N2Hza3jlbVJ22OeXacEWaCaVEqnqibLvYhf5VXYO3LZTeNvCUfUtDhUdxNBmkD9Fxa2Gk1gG9Lz0PMfYr0Nn1dI/qMMRbFV2GhzrVGpJErmi9SEcrmLvicfs+a2Wn081sufIVYSffuFm9SOEP9Rl/NX8kGrvR556U/xt8nCv4zmJqAlxvBsxYiIR7SYc0iO+m8Q/nqTa4KS228t90wAPzD5qjQ7uPtYwNDKKcLqijF8rL7H/RpADNQHySitRl5WSkax8nLYoZJK9pGImJqZi7GztsJrNspZdha1N2N0Cdvh+hJMVKs5VllJUUcfhm3UcOn+DymvlpKdekJz4VyE9aSb+xkgy9Z6V7CrTMxLvZf+l50jPziselH25vr7OFzIUou7TDlb4AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Comparison of the screen reader preview with the visual presentation of the test document.",
          "title": "Comparison of the screen reader preview with the visual presentation of the test document.",
          "src": "/static/03da873019463d92a7d723a399026111/c1b63/screenshot-pac-2024-test-pdf.png",
          "srcSet": ["/static/03da873019463d92a7d723a399026111/5a46d/screenshot-pac-2024-test-pdf.png 300w", "/static/03da873019463d92a7d723a399026111/0a47e/screenshot-pac-2024-test-pdf.png 600w", "/static/03da873019463d92a7d723a399026111/c1b63/screenshot-pac-2024-test-pdf.png 1200w", "/static/03da873019463d92a7d723a399026111/d61c2/screenshot-pac-2024-test-pdf.png 1800w", "/static/03da873019463d92a7d723a399026111/8d7fa/screenshot-pac-2024-test-pdf.png 1813w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3>{`No meaningful sequence`}</h3>
    <p>{`Take another close look at the screenshot above. Do you notice anything? Exactly, the image is visually in a different
position than in the screen reader preview.`}</p>
    <p>{`The reading order is saved separately in a tagged PDF. This allows a logical reading order to be defined, especially for
documents with complex layouts. For example, when blind users navigate through the document with a screen reader, the
virtual cursor of the screen reader follows this sequence.`}</p>
    <p>{`Therefore, `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/meaningful-sequence.html"
      }}>{`WCAG success criterion 1.3.2`}</a>{` defines
the following accessibility requirement:`}</p>
    <blockquote>
      <p parentName="blockquote">{`When the sequence in which content is presented affects its meaning, a correct reading sequence can be programmatically
determined.`}</p>
    </blockquote>
    <p>{`During a manual check, a person with an activated screen reader should therefore navigate sequentially through the document
and consider whether the sequence of content makes sense. The visual presentation serves as a guideline here. However, a
different reading order may also be meaningful.`}</p>
    <h3>{`Alternative text does not describe the image`}</h3>
    <p>{`The PDF Accessibility Checker only checks images to see whether they are tagged correctly and whether an alternative text
is defined. However, the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG22/Understanding/non-text-content.html"
      }}>{`WCAG success criterion 1.1.1`}</a>{`
requires more:`}</p>
    <blockquote>
      <p parentName="blockquote">{`All non-text content that is presented to the user has a text alternative that serves the equivalent purpose, except for
the situations listed below. `}{`[`}{`...`}{`]`}</p>
    </blockquote>
    <p>{`The picture in my test document shows the blue, open sea off the coast of Mallorca. The alternative text of the image,
however, reads `}<ItalicText mdxType="ItalicText">{`“A dust-dry desert”`}</ItalicText>{`. An automated tool cannot recognize that this is nonsense.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`The `}<BoldText mdxType="BoldText">{`PDF Accessibility Checker`}</BoldText>{` is a great tool for auditing a PDF document for major violations. However,
it does not replace manual testing. A comprehensive accessibility audit should include both automated and manual steps.`}</p>
    <p>{`PS: Also test the `}<a parentName="p" {...{
        "href": "/pdf/pdf_accessibility_test_en_corr.pdf"
      }}>{`corrected version of my test document`}</a>{` with a screen reader
to experience the improved user experience for yourself.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      